.Row-File-No-Process .Cell-Action-Process-File{
    display: none;
}
.Row-File-No-Process:hover .Cell-Action-Process-File{
    display: block;
}
.Row-File-No-Process:hover .Cell-Info-No-Process-File{
    display: none;
}
.Cell-Info-No-Process-File{
    color: red;
}
.Cell-Info-Process-File{
    color: #29aa29;
}

.Tabla-Carga-Archivos{
    margin-bottom: 0 !important;
    border-radius: 20px !important;
}

.Tabla-Carga-Archivos .ant-table-measure-row{
    display: none !important;
}
.Tabla-Carga-Archivos .ant-table{
    border: 1px solid #f0f0f0;
    border-bottom: 0;
    margin-top: 12px;    
}

.Tabla-Carga-Archivos .ant-table-thead th{
    font-size: 10px;
    padding: 3px 5px !important;
    font-family: 'Inter';
    background-color: #004fb8 !important;
    color: white !important;
}

.Tabla-Carga-Archivos .ant-table-thead th::before {
    display: none !important;
}

.Tabla-Carga-Archivos .ant-table-thead tr th span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.Tabla-Carga-Archivos .ant-table-tbody tr td{
    font-size: 10px;
    padding: 2px 8px !important;
}
.Tabla-Carga-Archivos .ant-table-tbody tr td div{
    font-family: 'Inter' !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    /* width: 100px; */
}

.Tabla-Carga-Archivos .ant-table-tbody tr td a{
    font-family: 'Inter' !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}


.Container-Sorter-Column-Carga-Archivos{
    display: flex;    
    align-items: center;
    justify-content: space-between;
}


.Tabla-Carga-Archivos .ant-table-thead .ant-table-cell-scrollbar{
    background-color: #E3F2FD !important;
}

.Tabla-Carga-Archivos tbody td {    
    white-space: nowrap !important;
    word-break:break-word !important;
    word-break: break-all !important;
}
.Tabla-Carga-Archivos ::-webkit-scrollbar {
    height: 7px !important;
    width: 20px;
    background-color: transparent !important;
    border: 0 !important;
    border-radius: 50px !important;
}
.Tabla-Carga-Archivos ::-webkit-scrollbar-track{
    background-color: #013A81;
    border-radius: 10px;
    margin: 10px 30px;
}
.Tabla-Carga-Archivos .ant-table-column-sorter {
    display: none !important;
}