.Txt-Btn-Cargar-Data{
    font-size: 12px;
}

.Container-Notificacion-Cargar-Data{
    height: 100vh;
    width: 21%;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: -1px -1px 5px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    right: 4px;
    transition-timing-function: ease-out;
}
.Title-Notificacion-Cargar-Data{
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #000000;
}

.Container-Body-Notificacion-Cargar-Data{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85%;
}
.Container-Body-No-Data-Notificacion-Cargar-Data{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Container-Body-No-Data-Notificacion-Cargar-Data img{
    height: 40px;
    width: 40px;
}

.Buton-Notification-Cargar-Data{
    background-color: #ffffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
}
.Buton-Notification-Cargar-Data:hover{
    background-color: #E3F2FD;
}

.Collapse-Noti-Expanded{
    width: 99%;
    animation-name: animationNotification;
    animation-duration: 1s;
}

@keyframes animationNotification {
    from {
        width: 20%
    }
    to {
        width: 99%
    }
}

