.Menu-Log-Out{
    display: flex;
    margin-right: 25px;
    cursor: pointer;
    column-gap: 5px;
}
.Icon-User-Top{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Icon-User-Item-Top{
    font-size: 15px;
    color: blue;
}
.Icon-Arrow-Top{
    color: blue;
}