.Color-Icon-Blue{
    color: #1677ff;
}
.Color-Icon-Gray{
    color: gray;
}

.Container-Title-Column-Approvals{
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    text-transform: uppercase;
    cursor: pointer;
    align-items: center;
}

.Container-Action-Info-Table{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Button-Action-Reload{
    font-size: 14px;
    margin-right: 4px;
    color: #1677ff;
}

.Container-Title-Column-Approvals div{
    display: flex;
    flex-direction: column;
}
.ant-checkbox-wrapper{
    width: 14px;
    height: 14px;
}
