.Container-Header-Form{
    display: grid;
    column-gap: 10px;
    align-items: center;
}
.Add-Assign-Button, .Remove-Assign-Button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.Add-Assign-Button{
    background-color: #013A81;
    box-shadow: 0 0 4px #013A81;
}
.Remove-Assign-Button{
    background-color: #FFD9D7;
    box-shadow: 0 0 4px #FFD9D7;
    color: red;
}
.Container-Text-Group-Combo{
    text-align: center;
}
.Container-Text-Group-Combo, .Container-Text-Group-Sku{
    position: relative;
}
.Container-Text-Group-Sku{
    margin-left: 3px;
}
.Text-Group-Combo, .Text-Group-Sku{
    font-family: 'Inter';
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    left: 6px;
    top: -6px;
}
.Border-Group-Combo{
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #c6c6c6;
    left: 5px;
    bottom: -12px;
}
.Border-Group-Sku{
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #c6c6c6;
    bottom: -12px;
    left: 5px;
}
.Container-Tools{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.Text-Tools{
    font-family: 'Inter';
    margin-bottom: 0;
}
.Container-Tools .Form-Item-Select{
    width: 180px;
}
.Container-Tools .Form-Item-Select .ant-select-selector, .Container-Tools .Select-Bonificado .ant-select-selector{
    height: 29px;
}
.Container-Tools .Form-Item-Select input, .Container-Tools .Select-Bonificado input{
    height: 27px !important;
}
.Container-Tools .Form-Item-Select .ant-select-selection-placeholder, .Container-Tools .Select-Bonificado .ant-select-selection-placeholder{
    font-size: 12px;
    margin-top: -2px;
}
.Input-Tools{
    width: 100px;
    text-align: center;
    font-size: 12px;
}
.Input-Tools::placeholder{
    font-size: 12px;
}
.Container-Tools .Select-Bonificado{
    width: 80px;
}
.Row-Card-Assign-No-Homologados{
    display: flex;
    row-gap: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
}
.Card-Assign-No-Homologados{
    border-radius: 20px;
    background-color: rgb(62, 213, 198);
    border: 0 !important;
    min-height: 80px;
    height: 100%;
    max-height: 120px;
}
.Row-Card-Assign-No-Homologados .ant-col:nth-child(9) .Card-Assign-No-Homologados, .Row-Card-Assign-No-Homologados .ant-col:nth-child(10) .Card-Assign-No-Homologados, .Row-Card-Assign-No-Homologados .ant-col:nth-child(11) .Card-Assign-No-Homologados{
    background-color: rgb(102, 205, 138);
}
.Row-Card-Assign-No-Homologados .ant-col:nth-child(12) .Card-Assign-No-Homologados{
    background-color: #013A81;
}
.Row-Card-Assign-No-Homologados .ant-col:nth-child(13) .Card-Assign-No-Homologados, .Row-Card-Assign-No-Homologados .ant-col:nth-child(14) .Card-Assign-No-Homologados{
    background-color: rgb(255, 165, 0, .7);
}
.Row-Card-Assign-No-Homologados .ant-col:nth-child(15) .Card-Assign-No-Homologados{
    background-color: rgb(255, 0, 0, .8);
}
.Card-Assign-No-Homologados .ant-card-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    position: relative;
    padding: 15px 28px 15px 15px !important;
    overflow: hidden;
}
.Card-Assign-No-Homologados .ant-card-body::before, .Card-Assign-No-Homologados .ant-card-body::after{
    display: none;
}
.Card-Assign-No-Homologados .Icon-Assign{
    position: absolute;
    top: 12px;
    right: 12px;
    color: white;
    cursor: pointer;
}
.Icon-Assign-Table{
    position: absolute;
    top: 2px;
    right: 2px;
    color: #013A81;
    cursor: pointer;
}
.Title-Assign{
    font-family: 'Inter';
    font-size: 10px;
    margin-bottom: 5px;
    line-height: 1.2;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
}
.Text-Assign{
    font-family: 'Inter';
    color: white;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 0 !important;
}
.Card-Price-Date{
    border-radius: 10px;
    background-color: #013A81;
    text-align: center;
    padding: 5px 10px;
}
.Card-Text{
    color: white;
    text-transform: capitalize;
    margin-bottom: 0;
}
.Card-Price{
    margin-bottom: 0;
    color: white;
}
.Modal-Date .ant-modal-content{
    background-color: #fff;
}
.Modal-Date .ant-modal-confirm-content{
    width: 100% !important;
    max-width: 100% !important;
}
.Table-Home.Modal-No-Homologados .ant-table-tbody tr{
    cursor: pointer;
}
.Table-Home.Modal-No-Homologados .ant-table-tbody tr.Row-Active td{
    background-color: #d1d1d1 !important;
}
.Input-Cod-Un-Med{
    width: 90px;
}
.Input-Un-Med{
    width: 90px;
}
.Input-Un-Med .ant-select-selection-placeholder, .Input-Cod-Un-Med .ant-select-selection-placeholder, .Input-Un-Med input, .Input-Cod-Un-Med input{
    font-size: 10px;
}
.Input-Un-Med input, .Input-Cod-Un-Med input{
    height: 27px !important;
}
.Input-Cod-Un-Med .ant-select-selector{
    border-right: 0 !important;
    border-radius: 8px 0 0 8px;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    outline: none !important;
    height: 29px !important;
}
.Input-Cod-Un-Med-Dropdown, .Input-Un-Med-Dropdown{
    width: 245px !important;
    max-height: 145px;
    overflow-y: auto;
}
.Input-Cod-Un-Med-Dropdown::-webkit-scrollbar, .Input-Un-Med-Dropdown::-webkit-scrollbar{
    width: 7px;
    height: 7px;
}
.Input-Cod-Un-Med-Dropdown::-webkit-scrollbar-thumb, .Input-Un-Med-Dropdown::-webkit-scrollbar-thumb{
    background-color: #013A81;
    border: 0;
    border-radius: 50px;
}
.Input-Cod-Un-Med-Dropdown::-webkit-scrollbar-track, .Input-Un-Med-Dropdown::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin: 15px 0;
}
.Input-Cod-Un-Med-Dropdown .ant-select-item, .Input-Un-Med-Dropdown .ant-select-item{
    min-height: 26px;
}
.Input-Cod-Un-Med-Dropdown .ant-select-item-option-content, .Input-Un-Med-Dropdown .ant-select-item-option-content{
    font-size: 11px;
}
.Separation-Input{
    border: 1px solid #d9d9d9;
    border-right: 0;
    border-left: 0;
    height: 29px;
    line-height: 1.9;
}
.Input-Un-Med .ant-select-selector{
    border-left: 0 !important;
    border-radius: 0 8px 8px 0;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    outline: none !important;
    height: 29px !important;
}
.Input-Cod-Un-Med .ant-select-selector .ant-select-selection-placeholder, .Input-Un-Med .ant-select-selector .ant-select-selection-placeholder{
    text-align: center;
    line-height: 27px;
}
.Filter-Select{
    width: 90px;
}
.Filter-Select .ant-select-selector{
    height: 29px !important;
}
.Filter-Select .ant-select-selection-item, .Filter-Select .ant-select-selection-placeholder, .Container-Tools .Form-Item-Select .ant-select-selection-item{
    font-size: 10px;
    line-height: 27px !important;
}
.Filter-Select input{
    font-size: 12px;
    height: 27px !important;
}
.Filter-Input{
    width: 90px !important;
    max-width: 90px !important;
    font-size: 11px !important;
    height: 29px !important;
}
.Container-Input-Un-Med{
    display: flex;
    align-items: center;
}
.Visible-Table-Indicators{
    width: auto;
    height: auto;
}
.Container-Input{
    position: relative;
}
.Text-Placeholder-Input{
    position: absolute;
    font-family: 'Inter';
    top: -8px;
    left: 5px;
    background-color: #fafafa;
    z-index: 2;
    font-size: 8px;
    padding: 0 5px;
}
.Text-Placeholder-Input.Text-Spaced{
    top: -6px;
}
.Text-Placeholder-Input-Bottom{
    position: absolute;
    font-family: 'Inter';
    bottom: -14px;
    left: 0px;
    background-color: #fafafa;
    z-index: 2;
    font-size: 8px;
    padding: 0 5px;
}
.Text-Hml-Url{
    display: inline-block;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}