.Title-Product-Assignment{
    font-size: 16px;
    margin-bottom: 15px;
}
.Input-Product-Assignment{
    color: rgb(0, 0, 0, .7) !important;
}
.Container-Button-Assignment-Product{
    margin-top: 17px;
    display: flex;
    justify-content: flex-end;
}
.Table-Home.Table-Assignment-Product-Massive .ant-table-row{
    cursor: pointer;
}