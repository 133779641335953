.Container-Filter-Date-Status-Top{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
}

.Text-Table-Gray-Status{
    font-size: 10px;
    text-align: center;
    color: #706c64;
}
.Text-Table-Red-Status{
    font-size: 10px;
    text-align: center;
    color: red;
}

.Text-Alert-Need-Approve{
    font-size: 12px;
    line-height: 12px;
    color: orange;
}

.Status-Detail .ant-table-tbody tr td div{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Modal-Status-Detail .ant-modal-header .ant-modal-title{
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.Table-Status{
    box-shadow: 0px 0px 15px #D8DFE9 !important;
}
.Table-Status .ant-table-thead th{
    border: 0 !important;
}

.Date-Picker-Status-Top{
    border: 1px solid #ff8023 !important;
    margin: 0 8px;
    width: 100px;
    border-radius: 15px;
    cursor: pointer !important;
}

.ant-picker-month-panel .ant-picker-body .ant-picker-content{
    height: 140px !important;
}


.Date-Picker-Status-Top .ant-picker-suffix, .Date-Picker-Status-Top .ant-picker-clear{
    display: none;
}
.Date-Picker-Status-Top .ant-picker-input input{
    font-size: 12px !important;
    text-align: center;
    cursor: pointer !important;
    color: #004fb8 !important;
}

.Date-Picker-Status-Top .ant-picker-input{
    display: flex !important;
    justify-content: center !important;
}

.Text-Filter-Date-Status-Top{
    align-self: center;
    font-size: 12px;
    color: #004fb8;
    font-weight: 600;
}

.Status-Detail, .Table-Status{
    border-radius: 20px !important;
}
.Status-Detail::before{
    display: none !important;
}

.Table-Status::before{
    display: none !important;
}


.Status-Detail .ant-table-tbody td{
    padding: 0 8px !important;
    border-bottom: 1px solid #D7E8FF !important;
}

.Status-Detail .ant-table-container{
    box-shadow: 0px 0px 15px #D8DFE9 !important;
}


.Status-Detail .ant-table-tbody td{
    padding: 0 8px !important;
}

.Modal-Status-Detail .ant-modal-content{
    border-radius: 20px !important;
}

.Container-Metric-Status-Top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d7e8ff;
    box-shadow: 0 0 18px #d8dfe9;
    border-radius: 8px;
    padding: 6px 22px 6px 12px;
    width: 32% !important;
}

.Container-Metric-Status-Top img{
    margin-right: 4px;
}

.Text-Description-Percentage-Status-Top{
    font-weight: 400 !important;
}

.Container-Text-Metric-Status-Top{
    display: flex;
    align-items: center;
    color: #1ec0ed;
    font-size: 12px;
    font-weight: 600;
}
.Container-Text-Metric-Status-Top img{
    width: 35px;
    height: 35px;
}

.Cell-Table-Status{
    display: flex;
    flex-direction: column;
}

.Cell-Text-Gray-Table-Status{
    font-size: 10px;
    text-align: center;
    color: #706c64;
}
.Cell-Text-Gray-Table-Status-Initial{
    text-align: initial;
    font-size: 10px;
    color: #706c64;
}

.Color-Text-Red-Status{
    color: red;
}

.Color-Text-Gray-Status{
    color: #706c64;
}

.Color-Text-Green-Status{
    color: #1eec41;
}

.Color-Red-Status{
    background-color: red;
}
.Color-Green-Status{
    background-color: #1eec41;
}

.Box-Day-Late-Status{
    width: 15px;
    height: 7px;
    border-radius: 10px;
}

.Tooltip-Status-Table-Status .ant-tooltip-inner{
    font-size: 10px !important;
    min-height: 0 !important;
}

.Cell-Text-Blue-Table-Status{
    font-size: 12px;
    color: #004fb8;
    font-weight: 600;
}

.Cell-Text-Red-Table-Status{
    font-size: 10px;
    text-align: center;
    color: red;
}
.Cell-Text-Green-Table-Status{
    font-size: 10px;
    text-align: center;
    color: #1eec41;
}


.Cell-Text-Table-Status{
    font-size: 10px;
    text-align: center;
}

.Column-Sac-Status-Table-Status{
    display: none;
}

.Row-Sac-Table-Status:hover .Column-Sac-Status-Table-Status{
    display: block;
}

.Row-Ventas-Table-Status .Cell-Table-Status{
    margin-top: 0 !important;
    top: 10px !important;
}

.Column-Sac-Status-Pending-Table-Status{
    color: red;
    font-size: 10px;
}
.Row-Sac-Table-Status:hover .Column-Sac-Status-Pending-Table-Status{
    display: none;
}

.Table-Status .ant-table{
    border: 1px solid #f0f0f0;
    border-bottom: 0;
    margin-top: 12px;
    overflow-y: hidden !important;
}

.Table-Status .ant-table-thead th{
    font-size: 10px;
    padding: 5px !important;
    font-weight: 700 !important;
    font-family: 'Segoe UI';
    background-color: #004fb8 !important;
    /* background-color: #004fb8 !important; */
    color: #ffffff !important;
    line-height: 12px;
}

.Table-Status .ant-pagination{
    display: none;
}
.Status-Detail .ant-pagination{
    display: none;
}

.Pagination-Modal-Status{
    display: flex;
    justify-content: end;
}

.Table-Status .ant-table-body::-webkit-scrollbar{
    width: 7px;
    height: 7px;
}
.Table-Status .ant-table-body::-webkit-scrollbar-thumb{
    background-color: #013A81;
    border: 0;
    border-radius: 50px;
}
.Table-Status .ant-table-body::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin: 10px 20px;
}

.Table-Status .ant-table-thead th::before{
    display: none;
}

.Text-Percentage-Status-Top{
    color: #004fb8;
    font-size: 14px;
    font-weight: 700;
}

.Title-Modal-Status-Detail{
    color: #004fb8;
    font-size: 12px;
    font-weight: 800 !important;
}

.ant-picker-month-panel{
    font-size: 12px !important;
    width: 200px !important;
}
.ant-picker-month-panel .ant-picker-content tr{
    height: 15px !important;
}
.ant-picker-month-panel .ant-picker-content tr td{
    padding: 0 !important;
}

.Table-Status .ant-table{
    border: none !important;
    border-radius: 15px !important;
}