.Box-Status-User{
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.Color-Green{
    background-color: #1eec41;
}
.Color-Red{
    background-color: red;
}

/* Diseño base */
.Table-User{
    margin-bottom: 0 !important;
    border-radius: 20px !important;
}

.Table-User .ant-table-measure-row{
    display: none !important;
}

.Table-User .ant-table{
    border: 1px solid #f0f0f0;
    border-bottom: 0;
    margin-top: 12px;
}

.Table-User .ant-table-thead .ant-table-cell-scrollbar{
    background-color: #E3F2FD !important;
}

.Table-User .ant-table-thead th{
    font-size: 10px;
    padding: 3px 5px !important;
    font-family: 'Inter';
    background-color: #004fb8 !important;
    color: white !important;
}

.Table-User .ant-table-thead th::before {
    display: none !important;
}

.Table-User .ant-table-thead tr th span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Table-User .ant-table-body::-webkit-scrollbar{
    width: 7px !important;
    height: 7px !important;
}

.Table-User .ant-table-body::-webkit-scrollbar-thumb{
    background-color: #013A81 !important;
    border: 0 !important;
    border-radius: 50px !important;
}

.Table-User .ant-table-body::-webkit-scrollbar-track{
    background-color: transparent !important;
    border-radius: 10px !important;
    margin: 10px 20px !important;
}
.Table-User .ant-table-tbody tr td{
    font-size: 10px;
    padding: 4px 9px !important;
}
.Table-User.Table-Small .ant-table-tbody tr td{
    padding: 2px 9px !important;
}

.Table-User .ant-table-tbody tr td div{
    font-family: 'Inter';
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Table-User .ant-pagination{
    margin-top: 0 !important;
    font-family: 'Inter';
    font-size: 12px;
}

.Table-User .ant-pagination-item{
    margin: 0 5px !important;
}