.Audits-Circle-Estado{
    width: 15px;
    height: 15px;
    background-color: #1eec41;
    border-radius: 50%;
    margin: auto;
}
.Date-Audits .ant-picker-clear{
    display: block;
}

.Audits-Checkbox-Status{
    display: flex;
    flex-direction: column;
}
.Audits-Checkbox-Status label{
    margin-inline-start: 0 !important;
    margin: 2px 0 !important;
}